import React from 'react';
import { Helmet } from 'react-helmet';
import FeaturesSection from '../components/AIHubPage/FeaturesSection';
import HowItWorks1 from '../components/OmniPersonalizationPage/HowItWorks1';
import HowItWorks2 from '../components/OmniPersonalizationPage/HowItWorks2';
import Testmonials from '../components/Testimonials';
import TrustedCustomers from '../components/TrustedCustomers';
import pagesJsonLD from '../constants/pagesJsonLD';
import './RelevaAIHub.css';

const OmniPersonalization = () => {
  // Function to handle clicks on the card wrappers
  const handleCardWrapperClick = (e, slider) => {
    e.preventDefault(); // Prevent default behavior of the click event if needed
    showElements(`.slider${slider}`, 'image');
    showElements(`.text${slider}`, 'text');
    toggleActive(`.interactiveHeading${slider}`);
  };
  // Function to toggle "active" class on the clicked element and remove it from others
  const toggleActive = (className) => {
    // Get all elements with the specified class name
    const elements = document.querySelectorAll(className);

    // Remove "active" class from all elements
    document.querySelectorAll('.active').forEach((el) => {
      el.classList.remove('active');
    });

    // Add "active" class to the clicked element
    elements.forEach((element) => {
      element.classList.add('active');
    });
  };
  // Function to show all elements with the specified class name
  const showElements = (className, type) => {
    // Get all elements with the specified class name
    const elementsToShow = document.querySelectorAll(className);

    // Convert NodeList to an array using spread operator
    const elementsArray = [...elementsToShow];

    // Show all elements
    elementsArray.forEach((element) => {
      element.style.display = 'block';
    });

    // Hide all other images
    if (type === 'image') {
      const allImages = document.querySelectorAll('.interactiveSlider');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
    if (type === 'text') {
      const allImages = document.querySelectorAll('.interactiveText');
      allImages.forEach((image) => {
        if (!elementsArray.includes(image)) {
          image.style.display = 'none';
        }
      });
    }
  };

  return (
    <div className="releva-ai-hub omni-personalization">
      <Helmet>
        <title>Releva AI | Omni personalization</title>
        <meta
          name="description"
          content="Unlock the power of omni-personalization with Releva AI. Deliver seamless, personalized experiences across all customer touchpoints. Learn how our AI-driven solutions can enhance engagement and drive conversions."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.omniPersonalizationPage)}</script>
      </Helmet>
      <section className="else-statement">
        <div className="while-loop">
          <div className="relevas-ai-hub">Elevate Your Marketing Strategy With Tailored Product Offers</div>
          <div className="switch-case-ai">
            <div className="create-document-collaborate">
              Make every interaction on your website, search results, and multichannel both relevant and engaging with
              AI.
            </div>
          </div>
          <div className="break-point">
            <div className="button1">
              <a
                rel="noopener noreferrer"
                className="button-name2"
                target="_blank"
                href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
              >
                Try Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="call-stack">
        <img className="image-118-icon" loading="lazy" alt="" src="/Omnipersonalization/HeroImage.webp" />
      </section>
      <section className="global-scope">
        <div className="block-statement">
          <div className="achievements-recognition">{`Achievements & Recognition `}</div>
          <div className="achievements-recognition-parent">
            <div className="arithmetic-operator">
              <div className="relevas-global-impact">Releva's global impact on eCommerce from around the world.</div>
            </div>
          </div>
          <div className="frame-container">
            <div className="frame-wrapper"></div>
            <div className="awardsHolder">
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements1.webp" />
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements2.webp" />
              <img className="image-118-icon" loading="lazy" alt="" src="/AIHub/Achievements3.webp" />
            </div>
          </div>
          <div className="array-operator">
            <div className="object-operator">
              <div className="function-define">
                <div className="why-your-ecommerce">Why Your eCommerce Business Needs Omni Personalization?</div>
              </div>
              {/* make it interactive */}
              <div className="variable-declare">
                <div className="variable-update">
                  <div className="statement-list">
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading1 active"
                      onClick={(e) => handleCardWrapperClick(e, 1)}
                    >
                      <b className="struggling-to-persuade">Are lack of personalization limiting your sales growth?</b>
                      <div className="convey-in-the interactiveText text1">
                        Provide the right products to the right people. Offer an exceptional shopping experience by
                        analyzing user behavior to deliver tailored product suggestions and communication.
                      </div>
                    </div>
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading2"
                      onClick={(e) => handleCardWrapperClick(e, 2)}
                    >
                      <b className="struggling-to-persuade">Need help reducing your high abandonment rates?</b>
                      <div className="convey-in-the interactiveText text2">
                        Use predictive analytics to understand user preferences and decrease abandonment rates. Predict
                        upcoming purchases and sessions, and deliver tailored offers at key moments to boost engagement
                        and reduce basket abandonment.
                      </div>
                    </div>
                    <div
                      className="struggling-to-persuade-your-au-parent interactiveHeading3"
                      onClick={(e) => handleCardWrapperClick(e, 3)}
                    >
                      <b className="struggling-to-persuade">
                        Is ineffective retargeting draining your marketing budget?
                      </b>
                      <div className="convey-in-the interactiveText text3">
                        Retarget customers with personalized product offers based on their recent searches. Boost the
                        effectiveness of your marketing campaigns. Choose the most effective channel for your audience,
                        optimize your retargeting costs, and increase conversion rates by 30%
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="image-118-icon interactiveSlider  slider1"
                  loading="lazy"
                  alt=""
                  src="/Omnipersonalization/WhySlider1.webp"
                />
                <img
                  className="image-118-icon interactiveSlider slider2"
                  loading="lazy"
                  alt=""
                  src="/Omnipersonalization/WhySlider2.webp"
                />
                <img
                  className="image-118-icon interactiveSlider slider3"
                  loading="lazy"
                  alt=""
                  src="/Omnipersonalization/WhySlider3.webp"
                />
              </div>
              {/* make it interactive */}
            </div>
          </div>
        </div>
      </section>
      <Testmonials />

      <section className="loop-nesting">
        <div className="function-call-stack">
          <div className="local-variable-table">
            <div className="global-variable-table">
              <div className="try-releva-ai">Try Releva AI Hub Free for 10 days </div>
              <div className="expression-tree">
                <div className="button3">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks">
            <div className="title">
              <div className="how-releva-ai">How Releva Omni Personalization Works</div>
              <div className="releva-ai-hub1">Releva Omni Personalization consists of</div>
            </div>
            <div className="sub-menu">
              <div className="menu-items">
                <div className="brand-identity-wrapper">
                  <div className="brand-identity1">
                    <a className="dummyLink" href="#productRecommendations">
                      Product Recommendations
                    </a>
                  </div>
                </div>
                <div className="create-content-wrapper">
                  <div className="create-content">
                    <a className="dummyLink" href="#browseAndBasketAbandonment">
                      Browse & Basket abandonment
                    </a>
                  </div>
                </div>
                <div className="generated-content-wrapper">
                  <div className="generated-content">
                    <a className="dummyLink" href="#predictNextBasket">
                      Predict next basket 
                    </a>
                  </div>
                </div>
                <div className="ai-chat-wrapper">
                  <div className="ai-chat">
                    <a className="dummyLink" href="#personalizedSearch">
                      Personalized search
                    </a>
                  </div>
                </div>
                <div className="ai-chat-wrapper">
                  <div className="ai-chat">
                    <a className="dummyLink" href="#omniChannelCommunication">
                      Omnichannel communication
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="howitworks1-wrapper">
            <div className="howitworks1" id="productRecommendations">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">01</div>
                  </div>
                  <div className="extract-your-brand">Smart Suggestions:</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">
                      Boost Conversions & Sales with Tailored Product Recommendations 
                    </div>
                  </div>
                  <div className="simply-provide-your">
                    By analyzing user behaviors, Releva provides suggestions that resonate with each individual user’s
                    preferences, significantly boosting satisfaction and increasing sales through a personalized
                    shopping experience.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/Omnipersonalization/omni1.webp" />
            </div>
          </div>

          <div className="howitworks3-ai-wrapper" id="browseAndBasketAbandonment">
            <HowItWorks1 />
          </div>

          <div className="howitworks1-wrapper" id="predictNextBasket">
            <div className="howitworks1">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">03</div>
                  </div>
                  <div className="extract-your-brand">Predict Next Baskets</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">with Advanced Analytics</div>
                  </div>
                  <div className="simply-provide-your">
                    Anticipate customer needs, predict and fulfill future purchases. With Releva’s predictive insights,
                    retailers can foresee and prepare for future customer needs, enhancing retention and encouraging
                    repeat business by staying one step ahead of demand.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/Omnipersonalization/omni3.webp" />
            </div>
          </div>

          <div className="howitworks3-ai-wrapper" id="personalizedSearch">
            <HowItWorks2 />
          </div>

          <div className="howitworks1-wrapper" id="omniChannelCommunication">
            <div className="howitworks1">
              <div className="howitworks1-inner">
                <div className="frame-parent7-ai">
                  <div className="wrapper">
                    <div className="div">05</div>
                  </div>
                  <div className="extract-your-brand">Engaging And Personalized</div>
                  <div className="description-and-persona-for-co-wrapper">
                    <div className="description-and-persona">Messaging Across All Platforms</div>
                  </div>
                  <div className="simply-provide-your">
                    Ensure consistent and responsive communication across emial, SMS, Viber, WhatsApp and other
                    communication platforms. Strengthen brand loyalty and drive sales through a seamless omnichannel
                    strategy that resonates with customers at every touchpoint of their shopping journey.
                  </div>
                </div>
              </div>
              <img className="image-119-icon" loading="lazy" alt="" src="/Omnipersonalization/omni5.webp" />
            </div>
          </div>
        </div>
      </section>
      <TrustedCustomers />
      <section className="loop-nesting">
        <div className="function-call-stack">
          <div className="local-variable-table">
            <div className="global-variable-table">
              <div className="try-releva-ai">Try Releva AI Hub Free for 10 days </div>
              <div className="expression-tree">
                <div className="button3">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
                  >
                    Try Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FeaturesSection title="Releva goes beyond just product recommendations." />
      <Testmonials />
      <div className="separator"></div>
    </div>
  );
};

export default OmniPersonalization;
