import './FrameComponent13.css';

const FrameComponent13 = () => {
  return (
    <section className="product-page-inner2">
      <div className="i-04-parent">
        <div className="i-04">
          <img
            loading="lazy"
            src="/ProductPage/ConversionCatalyst.webp"
            alt="ConversionCatalyst"
            title="ConversionCatalyst"
            className="HomePageHeroImage"
          />
        </div>
        <div className="toast-notification">
          <div className="email-marketing-label">
            <h1 className="conversion-catalyst">Conversion Catalyst</h1>
            <div className="when-a-visitor1">
              When a visitor adds an item to their cart but abandons it, Releva becomes a conversion catalyst with cart
              abandonment emails, gently guiding them back to complete the purchase.
            </div>
            <button className="button18">
              <a
                rel="noopener noreferrer"
                className="button-name16"
                target="_blank"
                href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
              >
                Book a demo
              </a>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent13;
