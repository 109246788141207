import Block from '../components/TrendoUseCasePage/Block';
import Block1 from '../components/TrendoUseCasePage/Block1';
import Block2 from '../components/TrendoUseCasePage/Block2';
import FrameComponent29 from '../components/TrendoUseCasePage/FrameComponent29';
import TrustedCustomers from '../components/TrustedCustomers';
import './TrendoCaseStudy.css';

const TrendoCaseStudy = () => {
  return (
    <div className="trendo-case-study">
      <main className="menu">
        <section className="menu-inner">
          <div className="case-study-trendo-parent">
            <h1 className="case-study-1">Case study - TrendO</h1>
            <div className="trendobg-is-an-online-marketp-wrapper">
              <div className="trendobg-is-an">
                TrendO.bg is an online marketplace, offering a diverse shopping experience with over 500 brands and an
                extensive selection of over 90,000 items. 
              </div>
            </div>
          </div>
        </section>
        <section className="block-1-parent">
          <Block2 />
          <FrameComponent29 />
        </section>
        <section className="block-7-parent">
          <Block1 />
          <TrustedCustomers />
          <Block />
          <div className="error-handler">
            <div className="frame-parent">
              <div className="try-releva-free-for-30-days-parent">
                <h1 className="try-releva-free">Try Releva free for 30 days. </h1>
                <div className="book-a-session-with-one-of-our-wrapper">
                  <div className="book-a-session">
                    Book a session with one of our eCommerce experts and learn how Releva can help your business grow.
                  </div>
                </div>
              </div>
              <div className="data-splitter">
                <div className="button">
                  <a
                    className="button-name"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
                  >
                    Book a demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default TrendoCaseStudy;
