import './DataFlowController.css';

const DataFlowController = () => {
  return (
    <section className="data-flow-controller">
      <div className="data-visualizer">
        <div className="i-14">
          <img
            loading="lazy"
            src="/OverviewPage/AbandonedCartRecovery.webp"
            alt="AbandonedCartRecovery"
            title="AbandonedCartRecovery"
            className="HomePageHeroImage"
          />
        </div>
        <div className="data-visualizer-inner">
          <div className="abandoned-cart-recovery-parent">
            <h1 className="abandoned-cart-recovery">Abandoned Cart Recovery</h1>
            <div className="perfect-for-ecommerce">
              Perfect for ecommerce businesses looking to recover lost revenue.
            </div>
            <div className="button36">
              <a
                rel="noopener noreferrer"
                href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
                target="_blank"
              >
                <div className="button-name28">Book a demo</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataFlowController;
