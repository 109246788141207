const caseStudiesListing = [
  {
    image: '/CaseStudies/sportdepot.webp',
    category: 'Medium Turnover Goods',
    title: 'Sport Depot case study',
    shortDescription: '68.2% Click rate from automations',
    url: 'sportdepot',
    locked: false,
    heroTitle: 'Case study - Sport Depot',
    heroDescription:
      'SPORT DEPOT, a key player in the European sports industry under SPORT 2000 International, brings top-tier sports and lifestyle brands to its 3600+ outlets across 20 countries.',
    caseStudyData: [
      {
        number: 1,
        color: 'ivory',
        title: 'Products',
        description:
          'Sport Depot offers a diverse range of high-quality sports and lifestyle products, featuring leading brands like adidas, Nike, Puma, Reebok, and more to meet the needs of active individuals and enthusiasts.',
        image: 'CaseStudy1.webp',
      },
      {
        number: 2,
        color: 'ivory-mid',
        title: 'Customers',
        description:
          "Sport Depot's customers are enthusiasts and active individuals seeking a curated selection of top-tier sports products and lifestyle brands.",
        image: 'CaseStudy2.webp',
      },
      {
        number: 3,
        color: 'ivory',
        title: 'Key Objectives',
        description:
          'Sport Depot aims to provide personalized communication with its customers. They want to engage with their clients, providing personalized offers that fit exactly their needs.',
        image: 'CaseStudy3.webp',
      },
      {
        number: 4,
        color: 'ivory-mid',
        title: 'Sport Depot implemented',
        description: 'Product recommendations; Email personalizations; Promotional banners; Analytics',
        image: 'CaseStudy4.webp',
      },
    ],
    achievedResults: [
      { key: 'Open rate from manual campaigns', value: '25.2' },
      { key: 'Click rate from manual campaigns', value: '39.7' },
      { key: 'Conversion rate from campaigns', value: '5.7' },
      { key: 'Conversion rate from recommenders', value: '30' },
    ],
    keyMetrics: [
      { key: 'Average page views', value: '923 732' },
      { key: 'Average active users', value: '981 736' },
      { key: 'Emails sent', value: '1 774 135' },
    ],
    keyMetricsImage: 'CaseStudy5.webp',
    facebookMetrics: [],
  },
  {
    image: '/CaseStudies/trendo.webp',
    category: 'Medium Turnover Goods',
    title: 'TrendO case study',
    shortDescription: '68.2% Click rate from automations',
    url: 'trendo',
    locked: false,
    heroTitle: 'Case study - TrendO',
    heroDescription:
      'TrendO.bg is an online marketplace, offering a diverse shopping experience with over 500 brands and an extensive selection of over 90,000 items.',
    caseStudyData: [
      {
        number: 1,
        color: 'ivory',
        title: 'Products',
        description:
          'TrendO.bg offers a vast selection of over 90,000 items across fashion for men, women, and children, as well as home goods, and accessories.',
        image: 'CaseStudy1.webp',
      },
      {
        number: 2,
        color: 'ivory-mid',
        title: 'Customers',
        description:
          'As an online market place tailored to a diverse user base, TrendO.bg offers a wide selection of products and brands, ensuring that every shopper finds their perfect fit.',
        image: 'CaseStudy2.webp',
      },
      {
        number: 3,
        color: 'ivory',
        title: 'Key Objectives',
        description:
          'TrendO aims to provide personalized communication with its customers. They want to engage with their clients, providing personalized offers that fit exactly their needs.',
        image: 'CaseStudy3.webp',
      },
      {
        number: 4,
        color: 'ivory-mid',
        title: 'Trend0 implemented',
        description:
          'Automated email newsletters; AI marketing automation; Omnichannel communication; Personalization and omnichannel communication via Facebook ads',
        image: 'CaseStudy4.webp',
      },
    ],
    achievedResults: [
      { key: 'Open rate from automations', value: '60.3' },
      { key: 'Click rate from automations', value: '68.2' },
      { key: 'Conversion rate from automations', value: '3.4' },
      { key: 'Open rate from campaigns', value: '43.8' },
      { key: 'Conversion rate from campaigns', value: '2' },
    ],
    keyMetrics: [
      { key: 'Average page views', value: '1 203 367' },
      { key: 'Average active users', value: '129 669' },
      { key: 'Emails sent', value: '1 522 499' },
    ],
    keyMetricsImage: 'CaseStudy5.webp',
    facebookMetrics: [],
  },
  {
    image: '/CaseStudies/ebag.webp',
    category: 'Fast-Moving Consumer Goods',
    title: 'eBag case study',
    shortDescription: '68.2% Click rate from automation',
    url: 'ebag',
    locked: false,
    heroTitle: 'Case study - eBag',
    heroDescription:
      'eBag serves a wide range of customers in cities like Sofia, Plovdiv, Asenovgrad, Pazardzhik, Pernik, Bansko, Blagoevgrad, and nearby areas, the company prioritizes customer satisfaction by delivering genuine, high-quality items right to their doorsteps, promising a hassle-free and enjoyable shopping process.',
    caseStudyData: [
      {
        number: 1,
        color: 'ivory',
        title: 'Products',
        description:
          'For a delightful shopping experience, eBag offers highest-quality food, grocery items, and beverages at competitive prices.',
        image: 'CaseStudy1.webp',
      },
      {
        number: 2,
        color: 'ivory-mid',
        title: 'Customers',
        description:
          'eBag serves a wide range of customers in cities like Sofia, Plovdiv, Asenovgrad, Pazardzhik, Pernik, Bansko, Blagoevgrad, and nearby areas, the company prioritizes customer satisfaction by delivering genuine, high-quality items right to their doorsteps, promising a hassle-free and enjoyable shopping process.',
        image: 'CaseStudy2.webp',
      },
      {
        number: 3,
        color: 'ivory',
        title: 'Key Objectives',
        description:
          'eBag aims to provide personalized communication with its customers. They want to engage with their clients, providing personalized offers that fit exactly their needs.',
        image: 'CaseStudy3.webp',
      },
      {
        number: 4,
        color: 'ivory-mid',
        title: 'eBag implemented',
        description: 'Marketing personalization; On-site recommenders; Analytics;',
        image: 'CaseStudy4.webp',
      },
    ],
    achievedResults: [
      { key: 'Open Rate', value: '55' },
      { key: 'Click Rate', value: '22' },
      { key: 'Conversion Rate', value: '30' },
      { key: 'Increase in purchases', value: '40' },
      { key: 'Conversion rate from recommenders', value: '15' },
    ],
    keyMetrics: [
      { key: 'Average page views', value: '5 132 199' },
      { key: 'Average active users', value: '249 200' },
    ],
    keyMetricsImage: 'CaseStudy5.webp',
    facebookMetrics: [],
    testimonial: {
      title: 'eBag about Releva',
      description:
        '“Thanks to Releva we manage to offer our customers so much more personalized and relevant content. We manage to engage them, encourage them to make a purchase and increase the average shopping cart total.”',
      author: 'Eleonora Ivanova, Marketing automation expert, eBag',
      image: 'CaseStudy6.webp',
    },
  },
  {
    image: '/CaseStudies/moemax.webp',
    category: 'Slow-Moving Consumer Goods',
    title: 'Mömax case study',
    shortDescription: '12% conversion rate from recommenders',
    url: 'moemax',
    locked: true,
    heroTitle: 'Case study - Mömax',
    heroDescription:
      'Mömax is a go-to destination for affordable and stylish home furniture and decor. Offering a diverse range of items, the company is part of the global furniture giant XXXLutz, which consistently expands its presence across Europe, solidifying its position as a leading furniture retailer.',
    idForm: 'CasestudyMmax/formperma/Ff1KSoNzW-JLl95WCsjf-saUmq5fwZ7X9tyoyq34DU8?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/aiko.webp',
    category: 'Slow-Moving Consumer Goods',
    title: 'Aiko case study',
    shortDescription: '43.3% conversion rate from automations',
    url: 'aiko',
    locked: true,
    heroTitle: 'Case study - Aiko',
    heroDescription:
      "Aiko is a leading brand in Bulgaria, specializing in top-notch interior and exterior solutions for homes, offices, and gardens. As part of the Austrian XXXLutz Group, one of the world's largest furniture retailers, Aiko is committed to excellence.",
    idForm: 'CasestudyAiko/formperma/KeaB7PnCXQdbCaWP76RkQJa_pnwaKJpxj_6wnAcJUSs?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/address.webp',
    category: 'Slow-Moving Consumer Goods',
    title: 'Address Real Estate AD case study',
    shortDescription: '19.9% Average open rate',
    url: 'address',
    locked: true,
    heroTitle: 'Case study - Address Real Estate',
    heroDescription:
      'ADDRESS Real Estate AD is the largest Bulgarian real estate agency. Established in 1993, it provides professional services through a wide network of offices across the country, with over 500 trained consultants.',
    idForm: 'CasestudyAddressRealEstateAD/formperma/30vp3fy06iNYizMOk5-sf5BdKLJPwGxilTvP0yQF8XA?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/praktis.webp',
    category: 'Slow-Moving Consumer Goods',
    title: 'Praktis case study',
    shortDescription: '5% Conversion rate from recommenders',
    url: 'praktis',
    locked: true,
    heroTitle: 'Case study - Praktis',
    heroDescription:
      'Established in 1995, Praktis is a leading chain of construction hypermarkets in the Balkans, providing a diverse range of high-quality products at the lowest prices. With eight locations nationwide and 19 years of experience, they cater to professionals and DIY enthusiasts with construction materials, tools, and accessories.',
    idForm: 'CasestudyPraktis/formperma/Qfsg2bnyKxtuOGHPHM-Yi079eRaF7l5zbCsiSRsdwXs?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/ardes.webp',
    category: 'Slow-Moving Consumer Goods',
    title: 'Ardes case study',
    shortDescription: '54.3% Conversion rate from recommenders',
    url: 'ardes',
    locked: true,
    heroTitle: 'Case study - Ardes',
    heroDescription:
      'Ardes.bg has been operating since 1999, offering a wide range of products and services including IT and software consultation, delivery of computer systems and laptops, diverse computer components, network construction, and comprehensive business information solutions.',
    idForm: 'CasestudyArdes/formperma/LTMAshIvhT9ZyvLPiD2QfS19_6NqwasWppfWwFxGjvA?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/teodor.webp',
    category: 'Medium Turnover Goods',
    title: 'Teodor case study',
    shortDescription: '13% Conversion rate from recommenders',
    url: 'teodor',
    locked: true,
    heroTitle: 'Case study - Teodor',
    heroDescription:
      'TEODOR, an industry leader in corporate apparel, specializes in crafting tailored clothing solutions. Its wide array of colors, high-quality materials, and fabrics make it the preferred choice for creating cohesive business looks, including formal wear inspired by contemporary elegance.',
    idForm: 'CasestudyTeodor/formperma/WisxEStKVrJuHzBxxK4olbnpMcuh_eRTkwjsqp2HLsg?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/gianni.webp',
    category: 'Medium Turnover Goods',
    title: 'GiAnni case study',
    shortDescription: '5.6% Conversion rate from automations',
    url: 'gianni',
    locked: true,
    heroTitle: 'Case study - GiAnni',
    heroDescription:
      'GiAnni is a prominent Bulgarian footwear brand since 1995, specializing in high-quality shoes, including their own line and other well-known brands. GiAnni offers stylish, durable leather footwear with a focus on customer satisfaction.',
    idForm: 'CasestudyGiAnni/formperma/RKIoec-cjJVoUJxPKQhpNG6eGguFNvV5J13fkk3144U?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/unico.webp',
    category: 'Medium Turnover Goods',
    title: 'Unico case study',
    shortDescription: '30% Releva contrubited to Unico’s success',
    url: 'unico',
    locked: true,
    heroTitle: 'Case study - Unico',
    heroDescription:
      'Unico, an online fashion store since 2014, specializes in plus-size clothing for both women and men. The store features a vast selection of outlet items, offering over 7000 plus-size models from German, English, and French brands, along with more than 1500 second-hand clothing options in larger sizes.',
    idForm: 'CasestudyUnico/formperma/EijpQjpIjXvfOV9qULZ6W6CaYNTQtkpgIeWPofsrWCA?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/kinderland.webp',
    category: 'Medium Turnover Goods',
    title: 'Kinderland case study',
    shortDescription: '58.4% Click rate from automations',
    url: 'kinderland',
    locked: true,
    heroTitle: 'Case study - Kinderland',
    heroDescription:
      "Kinderland is the largest Bulgarian company specializing in children's clothing and accessories. The company has experience in the Bulgarian market for more than 20 years and is well-known for the finest quality children's clothing at competitive prices.",
    idForm: 'CasestudyKinderland/formperma/nLBELm2fEYB_f-qtzJc1-eTf1arQ663JKWCjdMzyzPY?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/sexopolis.webp',
    category: 'Medium Turnover Goods',
    title: 'Sexopolis case study',
    shortDescription: '54% Conversion rate from recommenders',
    url: 'sexopolis',
    locked: true,
    heroTitle: 'Case study - Sexopolis',
    heroDescription:
      'Sexopolis is a sex shop, leader in the Greek market. The company takes pride in providing a huge variety of products that satisfy the desires of every customer.',
    idForm: 'CasestudySexopolis/formperma/hfa85w8Fm2NaAe8JTw2m7b-HRxczCTHDgmR6xE5gjSQ?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/kalinachi.webp',
    category: 'Medium Turnover Goods',
    title: 'Calinachi case study',
    shortDescription: '43% Conversion rate from recommenders',
    url: 'calinachi',
    locked: true,
    heroTitle: 'Case study - Calinachi',
    heroDescription:
      "Calinachi, a rising star in Bulgaria's beauty market, stands out for its high standards and unique natural formulas. Their skincare and haircare products, designed for sensitive skin and damaged hair, deliver lasting results.",
    idForm: 'CasestudyCalinachi/formperma/re1qMFhS1S2xet8h4kWd3560Qq5C7OvixeEgUSwLG4E?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/beautyprinces.webp',
    category: 'Medium Turnover Goods',
    title: 'Beauty Princess case study',
    shortDescription: '43.1% Conversion rate from recommenders',
    url: 'beautyprincess',
    locked: true,
    heroTitle: 'Case study - Beauty Princess',
    heroDescription:
      'Beauty Princess is an online beauty and fashion eCommerce shop based in Athens, Greece, with a unique personality and a mission to celebrate beauty in all its forms. The company expertise in beauty, cosmetics, makeup, and jewelry, and offers a high quality product selection.',
    idForm: 'CasestudyBeautyPrincess/formperma/LpBLcAsF-UzIEZDrrz3WySznEfzuAcneh0mfAus0g5M?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/tmarket.webp',
    category: 'Fast-Moving Consumer Goods',
    title: 'T-market case study',
    shortDescription: '90.4% Conversion rate from recommenders',
    url: 'tmarket',
    locked: true,
    heroTitle: 'Case study - T-market',
    heroDescription:
      'T MARKET, a trusted supermarket chain in 71 cities across Bulgaria, provides comprehensive shopping solutions with a diverse range of fresh groceries and a rich assortment of non-food items through its 124 physical stores and online platforms available in Sofia and Plovdiv.',
    idForm: 'CasestudyTmarket/formperma/EJOPLWN7TqKUjsK29otr3pHLkG31JkUhizgFQoUIn7I?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/zoomagazin.webp',
    category: 'Fast-Moving Consumer Goods',
    title: 'Zoomagazin case study',
    shortDescription: '30.9% Open rate from automations',
    url: 'zoomagazin',
    locked: true,
    heroTitle: 'Case study - Zoomagazin',
    heroDescription:
      'Established in 2005, it stands as the largest and most popular online pet store in Bulgaria. Offering over 50,000 items for your beloved pets, the store features competitive prices, door-to-door delivery for every customer, ongoing promotions and discounts for loyal customers.',
    idForm: 'CasestudyZoomagazin/formperma/YjvWkK86imjcXnFRIDLJpbtxTyD4nQxMCTOQkpV0RQc?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/zelen.webp',
    category: 'Fast-Moving Consumer Goods',
    title: 'Zelen case study',
    shortDescription: '22% Releva contrubited to Zelen’s success',
    url: 'zelen',
    locked: true,
    heroTitle: 'Case study - Zelen',
    heroDescription:
      'Zelen.bg, founded in 2009 in Sofia, Bulgaria, is one of the largest health food retailers in the country.  The company offers a thoughtfully curated range of high-quality, natural products, emphasizing that healthy eating can be enjoyable.',
    idForm: 'CasestudyZelen/formperma/uFlMUwYfQ_9rjDHMOoSwVH39htx7HGJ8MN6eXC6nU5Y?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/dabov.webp',
    category: 'Fast-Moving Consumer Goods',
    title: 'DABOV Specialty Coffee case study',
    shortDescription: '64.6% Conversion rate from Facebook Ads',
    url: 'dabov',
    locked: true,
    heroTitle: 'Case study - DABOV Specialty Coffee',
    heroDescription:
      'Dabov Coffee is a leading specialty coffee company based in Bulgaria, famous for its exceptional selection of ethically sourced beans from around the world. With a focus on quality and sustainability, every batch of Dabov coffee is meticulously roasted, ensuring a rich and distinctive flavor in every cup.',
    idForm: 'CasestudyDABOVSpecialtyCoffee/formperma/HCMTyS84oS58v_gGH-3N9oMeYgpJDkExnNKI5NbUVRw?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/cashcredit.webp',
    category: 'Fast-Moving Consumer Goods',
    title: 'Cash Credit case study',
    shortDescription: '46.7% Click Rate from automations',
    url: 'cashcredit',
    locked: true,
    heroTitle: 'Case study - Cash Credit',
    heroDescription:
      'Cash Credit is an innovative FinTech company offering microfinance services in cooperation with mobile telecom operators and other partners. The company was founded in 2011 in Bulgaria and has expanded internationally, launching operations in Africa, and South-East Asia, and exploring opportunities in other markets around the world.',
    idForm: 'CasestudyCashCredit/formperma/XwcaXWWPKML7sMPuSqvQKvRgjBH4rKRVdkBoNxJb4aE?zf_rszfm=1',
  },

  {
    image: '/CaseStudies/humanpet.webp',
    category: 'Fast-Moving Consumer Goods',
    title: 'Human Pet case study',
    shortDescription: '14.3% Conversion rate from automations',
    url: 'humanpet',
    locked: true,
    heroTitle: 'Case study - Human Pet',
    heroDescription:
      'Human Pet, the ultimate destination for premium solutions for pet health & wellbeing. With personalized services, expert veterinary guidance, and a convenient online platform, they prioritize the well-being of your furry friends, providing high-quality products with just a click or call away.',
    idForm: 'CasestudyHumanPet/formperma/jbSFllW9wv36eYB7E0PPuD1RP7yy6VHK-m6Uf2F4kXg?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/carducci.webp',
    category: 'Medium Turnover Goods',
    title: 'Carducci case study',
    shortDescription: '7.3% Conversion rate from automations',
    url: 'carducci',
    locked: true,
    heroTitle: 'Case study - Carducci',
    heroDescription:
      "Carducci, a distinguished name in men's fashion, epitomizes Italian elegance with a contemporary twist. Originating from Florence, Carducci's meticulously tailored suits are the result of decades of expertise and an unwavering commitment to quality.",
    idForm: 'CasestudyCarducci/formperma/To6IYEsbYHo65T0mIXCBZwFqYsqk2-Xp_z6fDoIqis8?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/wacaco.webp',
    category: 'Slow-Moving Consumer Goods',
    title: 'Wacaco case study',
    shortDescription: '13.5% Conversion rate from automations',
    url: 'wacaco',
    locked: true,
    heroTitle: 'Case study - Wacaco',
    heroDescription:
      'Wacaco offers innovative, portable coffee machines designed to deliver authentic espresso on the go, ensuring high-quality coffee experiences anywhere, anytime.',
    idForm: 'CasestudyWacaco/formperma/SeCOOqAJqr3mzZfZikffgFOOfoHzwlVB11pufyAqegc?zf_rszfm=1',
  },
  {
    image: '/CaseStudies/ariete.webp',
    category: 'Slow-Moving Consumer Goods',
    title: 'Ariete case study',
    shortDescription: '12.9% Conversion rate from automations',
    url: 'ariete',
    locked: true,
    heroTitle: 'Case study - Ariete',
    heroDescription:
      'Ariete offers innovative and stylish small household appliances, designed to provide practical and affordable solutions for everyday use.',
    idForm: 'CasestudyAriete/formperma/PITs8ZHxZ7Xe-kwjMvC5PHLsipH9Q5BtNWpYT2NDDyg?zf_rszfm=1',
  },
];

export default caseStudiesListing;
