import './LogicGate2.css';

const LogicGate2 = () => {
  return (
    <section className="logic-gate12">
      <div className="frame-parent96">
        <div className="frame-wrapper37">
          <div className="engagement-conversion-parent">
            <h1 className="engagement-conversion">{`Engagement & Conversion`}</h1>
            <div className="unlocking-ecommerce-success">
              Unlocking eCommerce success through Engagement and Conversion.
            </div>
            <div className="button31">
              <a
                rel="noopener noreferrer"
                href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
                target="_blank"
              >
                <div className="button-name28">Book a demo</div>
              </a>
            </div>
          </div>
        </div>
        <div className="i-11">
          <img
            loading="lazy"
            src="/OverviewPage/EngagementConversion.webp"
            alt="EngagementConversion"
            title="EngagementConversion"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default LogicGate2;
