import './Steps.css';

const Steps = () => {
  return (
    <section className="steps">
      <div className="section-051">
        <div className="wizard">
          <div className="tool-tip">
            <div className="carousel">01</div>
            <div className="gallery">
              <h1 className="omnichannel-mastery">Omnichannel Mastery</h1>
              <div className="from-viber-and-container">
                <p className="from-viber-and">
                  From Viber and WhatsApp messages to SMS and precision-targeted Facebook ads, Releva is your
                  comprehensive solution for audience engagement and conversion.
                </p>
                <p className="blank-line">&nbsp;</p>
                <p className="releva-doesnt-stop">
                  Releva doesn't stop at web personalization. It's an omnichannel wizard, reaching customers through
                  push notifications and pop-up banners, delivering that personal touch.
                </p>
              </div>
              <button className="button19">
                <a
                  rel="noopener noreferrer"
                  className="button-name17"
                  target="_blank"
                  href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
                >
                  Book a demo
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="frame-parent64">
          <img
            loading="lazy"
            src="/ProductPage/OmnichannelMastery.webp"
            alt="OmnichannelMastery"
            title="OmnichannelMastery"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default Steps;
