import './DijkstraAlgorithm.css';

const DijkstraAlgorithm = () => {
  return (
    <section className="dijkstra-algorithm">
      <div className="bellman-ford-algorithm">
        <div className="i-12">
          <img
            loading="lazy"
            src="/OverviewPage/OmniChannelMarketing.webp"
            alt="OmniChannelMarketing"
            title="OmniChannelMarketing"
            className="HomePageHeroImage"
          />
        </div>
        <div className="record-merger">
          <div className="omnichannel-marketing-parent">
            <h1 className="omnichannel-marketing">Omnichannel Marketing</h1>
            <div className="effortlessly-manage-communicat">
              Effortlessly manage communication across digital channels, offering seamless multichannel integration.
            </div>
            <div className="button34">
              <a
                rel="noopener noreferrer"
                href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
                target="_blank"
              >
                <div className="button-name28">Book a demo</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DijkstraAlgorithm;
