import { useEffect } from 'react';

const HubSpotForm = ({ idForm }) => {
  useEffect(() => {
    // Create a function to embed the Zoho form
    const embedZohoForm = () => {
      try {
        var f = document.createElement('iframe');
        f.src = `https://forms.zohopublic.eu/mariageorgieva/form/${idForm}`;
        f.style.border = 'none';
        f.style.height = '902px';
        f.style.width = '90%';
        f.style.transition = 'all 0.5s ease';
        f.setAttribute('aria-label', 'Case study - Aiko');

        const d = document.getElementById('zohoForm');
        d.appendChild(f);

        // Add message event listener to handle dynamic height changes
        const handleMessage = (event) => {
          const evntData = event.data;
          if (evntData && evntData.constructor === String) {
            const zf_ifrm_data = evntData.split('|');
            if (zf_ifrm_data.length === 2 || zf_ifrm_data.length === 3) {
              const zf_perma = zf_ifrm_data[0];
              const zf_ifrm_ht_nw = `${parseInt(zf_ifrm_data[1], 10) + 15}px`;
              const iframe = document.getElementById('zohoForm').getElementsByTagName('iframe')[0];

              if (iframe.src.indexOf('formperma') > 0 && iframe.src.indexOf(zf_perma) > 0) {
                const prevIframeHeight = iframe.style.height;
                let zf_tout = false;

                if (zf_ifrm_data.length === 3) {
                  iframe.scrollIntoView();
                  zf_tout = true;
                }

                if (prevIframeHeight !== zf_ifrm_ht_nw) {
                  if (zf_tout) {
                    setTimeout(() => {
                      iframe.style.height = zf_ifrm_ht_nw;
                    }, 500);
                  } else {
                    iframe.style.height = zf_ifrm_ht_nw;
                  }
                }
              }
            }
          }
        };

        window.addEventListener('message', handleMessage, false);

        // Cleanup on component unmount
        return () => {
          window.removeEventListener('message', handleMessage);
          if (d.firstChild) {
            d.removeChild(d.firstChild);
          }
        };
      } catch (e) {
        console.error('Error embedding Zoho form:', e);
      }
    };

    embedZohoForm();
  }, []);

  return <div id="zohoForm"></div>; // Placeholder for the Zoho form
};

export default HubSpotForm;
