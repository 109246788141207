import './FrameComponent12.css';

const FrameComponent12 = () => {
  return (
    <section className="section-06-wrapper">
      <div className="section-061">
        <div className="section-06-inner">
          <div className="parent9">
            <div className="div12">02</div>
            <div className="your-message-everywhere-parent">
              <h1 className="your-message-everywhere">Your Message Everywhere</h1>
              <div className="seamlessly-engaging-users">
                Seamlessly engaging users beyond your website, ensuring your message reaches them no matter where they
                are.
              </div>
              <button className="button20">
                <a
                  rel="noopener noreferrer"
                  className="button-name18"
                  target="_blank"
                  href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
                >
                  Book a demo
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="frame-parent67">
          <img
            loading="lazy"
            src="/ProductPage/YourMessageEverywhere.webp"
            alt="OmnichannelMastery"
            title="OmnichannelMastery"
            className="HomePageHeroImage"
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent12;
