import './FrameComponent23.css';
import Person from './Person';

const FrameComponent23 = () => {
  return (
    <section className="custom-integrations-wrapper" id="integration">
      <div className="custom-integrations">
        <div className="custom-integrations-inner">
          <div className="custom-integrations-parent">
            <h1 className="custom-integrations1">Custom Integrations</h1>
            <div className="we-provide-seamless2">We provide seamless integration with major eCommerce platforms.</div>
          </div>
        </div>
        <div className="frame-parent95">
          <div className="path-finder-wrapper">
            <div className="path-finder1">
              <Person
                prop="1"
                vuesaxlinearlink="/vuesaxlinearlink.svg"
                addRelevaSDKToYourAppEith="Add Releva SDK to your app, either web or mobile"
              />
              <Person
                prop="2"
                vuesaxlinearlink="/vuesaxlinearglobal.svg"
                addRelevaSDKToYourAppEith="Start collecting events from home, category, product, search and card pages"
                propPadding="var(--padding-5xl) var(--padding-3xl) var(--padding-5xl) var(--padding-7xl)"
                propGap="33px"
                propMinWidth="15px"
                propWidth="258px"
              />
              <Person
                prop="3"
                vuesaxlinearlink="/vuesaxlinearimport.svg"
                addRelevaSDKToYourAppEith="Add recommendations and banner placements"
                propPadding="var(--padding-5xl) var(--padding-6xl) var(--padding-33xl) var(--padding-7xl)"
                propGap="33px"
                propMinWidth="16px"
                propWidth="231px"
              />
              <Person
                prop="4"
                vuesaxlinearlink="/vuesaxlinearhierarchy.svg"
                addRelevaSDKToYourAppEith="Create your first marketing flow"
                propPadding="var(--padding-2xl) var(--padding-3xl) var(--padding-36xl) var(--padding-4xl)"
                propGap="61px"
                propMinWidth="16px"
                propWidth="unset"
              />
              <Person
                prop="5"
                vuesaxlinearlink="/vuesaxlinearaward.svg"
                addRelevaSDKToYourAppEith="Send your first weekly newsletter with recommendations"
                propPadding="var(--padding-2xl) var(--padding-3xl) var(--padding-8xl) var(--padding-7xl)"
                propGap="61px"
                propMinWidth="15px"
                propWidth="284px"
              />
              <Person
                prop="6"
                vuesaxlinearlink="/vuesaxlinearcolorssquare.svg"
                addRelevaSDKToYourAppEith="Start optimizing"
                propPadding="var(--padding-2xl) var(--padding-6xl) var(--padding-36xl) var(--padding-7xl)"
                propGap="61px"
                propMinWidth="15px"
                propWidth="231px"
              />
            </div>
          </div>
          <div className="maximum-path-finder">
            <div className="get-demo-wrapper2">
              <div className="get-demo9">
                <div className="get-started6">
                  <a
                    rel="noopener noreferrer"
                    className="button-name27"
                    target="_blank"
                    href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true?__hstc=181257784.ba122faa6e79d71d964ba4af98d01386.1712924226627.1714450719011.1716788145208.29&amp;__hssc=181257784.15.1716788145208&amp;__hsfp=217158748"
                  >
                    Book a demo
                  </a>
                </div>
              </div>
            </div>
            <div className="b-f-s-queue">
              <div className="social-media1">
                <img className="footer-c-t-a" alt="" src="/vector-13.svg" />
                <img className="vector-icon25" loading="lazy" alt="" src="/vector-14.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent23;
