import { Link } from 'react-router-dom';

import './Footer.css';

const Footer = () => {
  return (
    <footer className="releva-homepage-inner">
      <div className="footer-parent">
        <div className="footer1">
          <img className="cta-background-icon" loading="lazy" alt="" src="/cta-background.svg" />
          <div className="link-assembler">
            <h1 className="ready-to-see">Ready to see Releva in action?</h1>
          </div>
          <div className="row-stack">
            <div className="get-a-demo">
              Get a demo and discover how thousands of marketeres harness Releva for AI-fueled growth, and loyalty
              mastery.
            </div>
          </div>
          <div className="circle-pack">
            <button className="button5">
              <Link
                className="button-name5"
                to="https://calendar.google.com/calendar/appointments/schedules/AcZssZ19Gjz3Kty9Q4_6oEBYze_s-oNby2F-zxe3iz6L9WwD4Dp-CP-1s2zEHz4lpzlN19osS_wItpPG?gv=true"
              >
                Book a demo
              </Link>
            </button>
          </div>
          <div className="shape-collection">
            <div className="shape-collection-child" />
          </div>
          <div className="frame-parent31">
            <div className="frame-parent32">
              <div className="why-releva-parent">
                <div className="why-releva3">Why Releva</div>
                <div className="e-commerce-owners-parent">
                  <Link to="/overview">E-Commerce owners</Link>
                  <Link to="/data-driven-marketers">Marketing specialist</Link>
                  <Link to="/case-studies">Case Studies</Link>
                  {/* <div className="partners">Partners</div>
              <div className="referral-programs">Referral programs</div>
              <div className="shoppify-integration">Shoppify integration</div>
              <div className="merchant-pro-integration">
                Merchant pro integration
              </div> */}
                </div>
              </div>
              <div className="product-parent">
                <div className="product">Product</div>
                <div className="product-data-center-parent">
                  <Link to="/product">Releva Flagship</Link>
                  <Link to="/ai-hub">AI Hub</Link>
                  <Link to="/omni-personalization">Omni Personalization</Link>
                  <Link to="/mail-and-push">Mail & Push</Link>
                  <Link to="/message-hub">Message Hub</Link>
                  <Link to="/social-media">Social Media</Link>
                  <Link to="/customer-and-product-platform">Customer & product platform</Link>
                  {/* <a rel="noopener noreferrer" href="/pricing">
                    <div className="about2">Pricing</div>
                  </a> */}
                </div>
              </div>
              <div className="resources-parent">
                <div className="resources">Resources</div>
                <div className="documents-parent">
                  <Link to="/content-library?type=news">News</Link>
                  <Link to="/content-library?type=blog">Blog</Link>
                  <Link to="/content-library?type=press%20Release">Press Release</Link>
                </div>
              </div>
              <div className="company-group">
                <div className="company">Company</div>
                <div className="about-documents">
                  <Link to="/about-us">About</Link>
                  <Link to="/about-us#releva-story">Story</Link>
                  <Link to="/about-us#meet-the-team">Team</Link>
                  <Link to="/about-us#section=releva-vision">Vision</Link>
                  <Link to="/partners">Partners</Link>
                  {/* <div className="team">Team</div>
              <div className="why-work-with">Why work with us</div>
              <div className="careers">Careers</div> */}
                </div>
              </div>
            </div>
            <div className="facebook-parent">
              <Link className="terms-of-use" target="_blank" to="https://www.facebook.com/relevaai">
                <img
                  className="facebook-icon"
                  loading="lazy"
                  alt="facebook page"
                  src="/facebook.svg"
                  width="100%"
                  height="100%"
                />
              </Link>
              <Link target="_blank" className="terms-of-use" to="https://www.instagram.com/releva.ai/">
                <img className="instagram-icon" loading="lazy" alt="" src="/instagram.svg" width="100%" height="100%" />
              </Link>
              <Link target="_blank" className="terms-of-use" to="https://linkedin.com/company/releva-ai">
                <img className="linkedin-icon" loading="lazy" alt="" src="/linkedin.svg" width="100%" height="100%" />
              </Link>
            </div>
          </div>
          <div className="shape-collection1">
            <div className="promotional-banners1" />
          </div>
          <div className="releva-logo-frame">
            <img className="releva-logo-icon" loading="lazy" alt="" src="/releva-logo.svg" width="100%" height="100%" />
          </div>
          <div className="shape-collection2">
            <div className="shape-collection-item" />
          </div>
          <div className="footer-inner">
            <div className="releva-all-rights-reserved-parent">
              <div className="releva-all-rights">Releva AI, All Rights Reserved</div>
              <div className="image-container">
                <Link className="terms-of-use" to="/terms-of-use">
                  Terms of use
                </Link>
                <Link className="cookies" to="/cookie-policy">
                  Cookies
                </Link>
                <Link className="privacy-policy2" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
          <div className="footer-inner">
            <div className="fundingHolder">
              <p>
                An investor in Releva PLC is New Vision 3 Fund, which is co-financed by the European Structural and
                Investment Funds under the Operational Programme for Innovation and Competitiveness 2014-2020 managed by
                the Fund of Funds in Bulgaria.
              </p>
              <img src="/fundingImage.webp" loading="lazy" alt="" width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
